try {
    window.bootstrap = require ('bootstrap');

    require ('fslightbox');
} catch (e) {}

/*
import 'bootstrap';
import { Carousel } from 'bootstrap/js/dist/carousel.js';
import { Collapse } from 'bootstrap/js/dist/collapse.js';
import { Dropdown } from 'bootstrap/js/dist/dropdown.js';
import { Tooltip } from 'bootstrap/js/dist/tooltip.js';
*/