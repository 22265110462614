import './bootstrap';

/* JS EFFET NAVBAR AU TOP (version STUCK) */
(new IntersectionObserver(function (e) {
    if (e[0].intersectionRatio > 0) {
        document.documentElement.removeAttribute('class');
    } else {
        document.documentElement.setAttribute('class', 'stuck');
    };
})).observe(document.querySelector('.trigger'));

/* DISPLAY BS4 TOOLTIP */
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
})

/* FOR DESKTOP ONLY */
if (window.matchMedia("(min-width: 992px)").matches) {

    /* GO TOP */
    document.addEventListener("scroll", handleScroll);

    // get a reference to our predefined button
    var scrollToTopBtn = document.querySelector(".scrollToTopBtn");
    
    function handleScroll() {
      var scrollableHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      var GOLDEN_RATIO = 0.5;
    
      if ((document.documentElement.scrollTop / scrollableHeight ) > GOLDEN_RATIO) {
        //show button
        if (!scrollToTopBtn.classList.contains("showScrollBtn"))
            scrollToTopBtn.classList.add("showScrollBtn")
      } else {
        //hide button
        if (scrollToTopBtn.classList.contains("showScrollBtn"))
            scrollToTopBtn.classList.remove("showScrollBtn")
      }
    }
    
    scrollToTopBtn.addEventListener("click", scrollToTop);
    
    function scrollToTop() {
      window.scrollTo({
        right: 0,
        behavior: "smooth"
      });
    }
    
    /*
    WRAP IMG
    document.querySelectorAll('#main img').outerHTML

    document.querySelectorAll("#main img").forEach(currentImg => {
        currentImg.style.display = "none"
        org_html = document.getElementById("slidesContainer").innerHTML;
        new_html = "<div id='slidesInner'>" + org_html + "</div>";
        document.getElementById("slidesContainer").innerHTML = new_html;
    });
    */
};
